"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var COMMENTS_URL = '/api/comments';
var creating = false;
var submitButton = document.querySelector('#js-submit-button');
function serialize(form) {
    var formElements = Array.from(form.elements);
    return formElements.reduce(function (acc, field) {
        var _a;
        var name = field.name, disabled = field.disabled, type = field.type, value = field.value;
        if (!name || disabled || type === 'file' || type === 'reset' || type === 'submit' || type === 'button')
            return acc;
        return __assign(__assign({}, acc), (_a = {}, _a[name] = value, _a));
    }, {});
}
function drawComments(comments) {
    if (!comments.length)
        return;
    var commentString = comments.reduce(function (acc, comment) {
        var user = comment.user, website = comment.website, pubDate = comment.pubDate, gravatar = comment.gravatar, content = comment.content;
        return acc + ("\n      <div class=\"comment\">\n        <img alt=\"" + user + "\" src=\"" + gravatar + "\" width=\"48\" height=\"48\">\n        <div class=\"comment__text\">\n          <h5 class=\"page-user\">\n            " + (website ? "<a href=\"" + website + "\">" + user + "</a>" : user) + "\n            <span class=\"comment__date\">\n              " + pubDate + "\n            </span>\n          </h5>\n          <div class=\"font_small\">\n            <p>" + content + "</p>\n          </div>\n        </div>\n      </div>\n    ");
    }, '');
    document.querySelector('#js-comments').innerHTML = commentString;
}
var COMMON_OPTIONS = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    }
};
function createComment(newComment) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            creating = true;
            submitButton.disabled = true;
            submitButton.value = "Guardando...";
            fetch(COMMENTS_URL, __assign(__assign({}, COMMON_OPTIONS), { method: 'POST', body: JSON.stringify(newComment) }))
                .then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                var error, comments;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(response.status !== 200)) return [3, 2];
                            return [4, response.json()];
                        case 1:
                            error = _a.sent();
                            alert(error.message);
                            return [2];
                        case 2: return [4, response.json()];
                        case 3:
                            comments = _a.sent();
                            drawComments(comments);
                            document.querySelector('#content-field').value = '';
                            return [2];
                    }
                });
            }); })
                .finally(function () {
                creating = false;
                submitButton.disabled = false;
                submitButton.value = "Guardar Comentario";
            });
            return [2];
        });
    });
}
function setupComments() {
    document
        .querySelector('#js-comment-form')
        .addEventListener('submit', function (evt) {
        evt.preventDefault();
        if (creating)
            return;
        var serialized = serialize(evt.target);
        createComment(serialized);
    });
}
exports.default = setupComments;
